import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import ImageSlider from "../ImageSlider/ImageSlider";
import { ourTeam } from "./OurTeam.helper";
import Container from "@material-ui/core/Container";
import HeadingCTA from "../HeadingCTA/HeadingCTA";
import { mobileViewBreakpoint } from "../helper";

const useStyles = makeStyles(theme => ({
  ourTeamWrapper: {
    overflow: "hidden",
    marginBottom: "180px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      marginBottom: "64px",
      marginTop: "128px"
    }
  },
  headingCTAWrapper: {
    marginTop: "160px",
    marginBottom: "80px",

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      marginTop: "104px",
      marginBottom: "32px",
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginTop: "0px"
      }
    }
  }
}));

const OurTeam = ({ data, subheading, title }) => {
  const classes = useStyles();
  const theme = useTheme();

  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  return (
    <div className={classes.ourTeamWrapper}>
      <div className={classes.headingCTAWrapper}>
        <HeadingCTA subheading={subheading} title={title} center />
      </div>
      <ImageSlider data={data} isImageWithDetails isRelatedArticles />
    </div>
  );
};

export default OurTeam;
