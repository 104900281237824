import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Typography from "../../Typography/Typography";

import { mobileViewBreakpoint } from "../../helper";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const useStyles = makeStyles(theme => ({
  blogAuthorWrapper: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    background: "#FFFFFF",
    border: "1px solid rgba(0, 0, 0, 0.05)",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
    borderRadius: "6px",
    padding: "16px 32px",
    justifyContent: "space-between",
    marginTop: 80,

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "16px 24px 36px",
      margin: 40
    }
  },
  leftPanel: {
    display: "flex",
    alignItems: "center",

    "& .gatsby-image-wrapper": {
      borderRadius: "50%",
      minWidth: "70px",
      minHeight: "70px"
    },

    "& span": {
      marginLeft: 16
    }
  },
  rightPanel: {
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      paddingTop: "36px",
      justifyContent: "center",
      width: "100%"
    },

    "& a": {
      marginRight: "48px",
      display: "flex",
      aligItems: "center",

      "&:last-child": {
        marginRight: "0"
      },

      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginRight: 62.5
      }
    }
  }
}));

const BlogAuthor = ({ data, date }) => {
  const classes = useStyles();
  const theme = useTheme();
  const image = getImage(data.picture);
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  return (
    <div className={classes.blogAuthorWrapper}>
      <div className={classes.leftPanel}>
        {data && data.picture && <GatsbyImage image={image} />}
        <Typography
          customVariant={"h5Bold"}
          component="span"
          color="rgba(0, 0, 0, 0.5)"
        >
          {data && data.name} · {date}
        </Typography>
      </div>
      <div className={classes.rightPanel}>
        {/*
        <Link href="https://www.instagram.com/motusone1/" target="_blank">
          <img src={Instagram} />
        </Link>
       <Link
          href="https://www.linkedin.com/company/motus-one/"
          target="_blank"
        >
          <img src={LinkedIn} />
        </Link> 
        <Link href="https://www.facebook.com/motusone" target="_blank">
          <img src={FaceBook} />
        </Link>
        <Link href="https://twitter.com/motus1ne" target="_blank">
          <img src={Twitter} />
      </Link>*/}
      </div>
    </div>
  );
};

export default BlogAuthor;
