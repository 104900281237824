import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import { makeStyles } from "@material-ui/core/styles";
import parse from "html-react-parser";
import { useTheme } from "@material-ui/core/styles";
import Img from "gatsby-image";
import HeadingCTA from "../../components/HeadingCTA/HeadingCTA";
import MainMenu from "../../components/MainMenu/MainMenu";
import Header from "../../components/Header/Header";
import Container from "@material-ui/core/Container";
import Typography from "../../components/Typography/Typography";
import BlogAuthor from "../../components/Blog/BlogAuthor/BlogAuthor";
import { mobileViewBreakpoint } from "../../components/helper";
import CopyRights from "../../components/Footer/CopyRights/CopyRights";
import Footer from "../../components/Footer/Footer";
import RelatedArticles from "../../components/OurTeam/OurTeam";
import { relatedArticles } from "../../components/Blog/related-article.helper";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "80px 32px",
    maxWidth: "1234px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "64px 20px"
    },
    "&  b": {
      color: "#122044",
      fontSize: "28px",
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        display: "block",
        background: "none",
        height: "auto"
      }
    }
  }
  // blogDetails: {
  //   padding: "80px 0px",
  //   [theme.breakpoints.down(mobileViewBreakpoint)]: {
  //     padding: "64px 0px",
  //   },
  //   "& b": {
  //     color: "#122044",
  //     fontSize: "28px",
  //     [theme.breakpoints.down(mobileViewBreakpoint)]: {
  //       display: "block",
  //       background: "none",
  //       height: "auto",
  //     },
  //   },
  // },
}));

export const query = graphql`
  query ArticleQuery($slug: String!) {
    strapiArticle(slug: { eq: $slug }, status: { eq: "published" }) {
      strapiId
      title
      description
      metaTitle
      metaDescription
      content
      publishedAt(formatString: "MMMM D, YYYY")
      imageAlt
      image {
        publicURL
        childImageSharp {
          fluid(maxHeight: 600) {
            ...GatsbyImageSharpFluid
          }
          fixed(width: 800, height: 800) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      author {
        name
        picture {
          childImageSharp {
            gatsbyImageData(width: 70, placeholder: BLURRED)
          }
        }
      }
    }
    bannerImage: file(relativePath: { eq: "blog/banner.png" }) {
      childImageSharp {
        fluid(maxHeight: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    ArticleOne: file(relativePath: { eq: "article/related/article1.png" }) {
      childImageSharp {
        fluid(maxHeight: 380) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ArticleTwo: file(relativePath: { eq: "article/related/article2.png" }) {
      childImageSharp {
        fluid(maxHeight: 380) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ArticleThree: file(relativePath: { eq: "article/related/article3.png" }) {
      childImageSharp {
        fluid(maxHeight: 380) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ArticleFour: file(relativePath: { eq: "article/related/article4.png" }) {
      childImageSharp {
        fluid(maxHeight: 380) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const Article = ({ data }) => {
  const classes = useStyles();
  const theme = useTheme();
  const article = data.strapiArticle;

  const seo = {
    metaTitle: article.metaTitle ? article.metaTitle : article.title,
    metaDescription: article.metaDescription,
    shareImage: article.image.childImageSharp.fixed.src,
    article: true
  };

  const relatedArticles = [
    {
      picture: data.ArticleOne.childImageSharp.fluid,
      name: "Blog post name",
      description: "October 10, 2020"
    },
    {
      picture: data.ArticleTwo.childImageSharp.fluid,
      name: "Blog post name",
      description: "October 10, 2020"
    },
    {
      picture: data.ArticleThree.childImageSharp.fluid,
      name: "Blog post name",
      description: "DOctober 10, 2020"
    },
    {
      picture: data.ArticleFour.childImageSharp.fluid,
      name: "Blog post name",
      description: "DOctober 10, 2020"
    },
    {
      picture: data.ArticleOne.childImageSharp.fluid,
      name: "Blog post name",
      description: "DOctober 10, 2020"
    },
    {
      picture: data.ArticleTwo.childImageSharp.fluid,
      name: "Blog post name",
      description: "DOctober 10, 2020"
    },
    {
      picture: data.ArticleThree.childImageSharp.fluid,
      name: "Blog post name",
      description: "DOctober 10, 2020"
    },
    {
      picture: data.ArticleFour.childImageSharp.fluid,
      name: "Blog post name",
      description: "DOctober 10, 2020"
    }
  ];

  return (
    <Layout seo={seo}>
      <MainMenu />
      <div>
        {/**
        <div
          id="banner"
          className="uk-height-medium uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-light uk-padding uk-margin"
          //data-src={article.image.publicURL}
          //data-srcset={article.image.publicURL}
          data-uk-img
        >
          <h1>{article.title}</h1>
        </div> */}
      
        <Header
          image={
            <Img
              fluid={article.image.childImageSharp.fluid}
              alt={article.imageAlt}
              style={{
                filter: "blur(5px)",
                transform: "scale(1.03)"
              }}
              
            />
          }
          halfHeight
          breadcrumbs={[
            { label: "Blog", link: "/blog/" },
            { label: article.title }
          ]}
          blur
        >
          <HeadingCTA largeWidth dark title={article.title} h1 />
        </Header>

        <Container maxWidth={false} className={classes.root}>
          <article>
            <Typography component="span" customVariant={"h5Regular"}>
              {parse(article.content)}
            </Typography>
          </article>

          {/* <div className="uk-grid-small uk-flex-left" data-uk-grid="true">
            <div>
              {article.author.picture && (
                <Img
                  fixed={article.author.picture.childImageSharp.fixed}
                  imgStyle={{ position: "static", borderRadius: "50%" }}
                />
              )}
            </div>
            <div className="uk-width-expand">
              <p className="uk-margin-remove-bottom">
                By {article.author.name}
              </p>
              <p className="uk-text-meta uk-margin-remove-top">
                <Moment format="MMM Do YYYY">{article.published_at}</Moment>
              </p>
            </div>
          </div> */}
          <BlogAuthor data={article.author} date={article.publishedAt} />
          <br />
        </Container>

        {/*
        <RelatedArticles
          data={relatedArticles}
          subheading="Blog Posts"
          title="Similar Articles"
        />
        */}

        <Footer />
        <CopyRights />
      </div>
    </Layout>
  );
};

export default Article;
